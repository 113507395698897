// Fonts
@font-face{
	font-family: 'NotoSans';
	src: url('../fonts/NotoSans-Regular.ttf') format(truetype);
} 

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

body{
	font-family: "NotoSans", sans-serif;
	font-size: 16px;
	font-weight: 400;
}
